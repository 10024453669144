.homepage-container {
    margin: 0 auto;
    padding: 20px;
    max-width: 800px;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
}

header {
    text-align: center;
    margin-bottom: 20px;
}

.contact-info {
    font-size: 0.9em;
}

h1 {
    margin-bottom: 10px;
}

h2 {
    color: #444;
    margin-bottom: 5px;
}

h3 {
    color: #555;
    margin-top: 20px;
}

h4 {
    color: #666;
    margin-bottom: 5px;
}

h5 {
    color: #777;
    margin-bottom: 5px;
}

p {
    margin: 5px 0;
}

ul {
    list-style-type: square;
    margin: 10px 0;
}

.experience-item {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px 0;
}

a {
    color: #1a73e8;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
