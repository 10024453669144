.cell {
    text-align: center;
    font-size: 55px;
    background-color: rgba(238, 228, 218, .35);
    font-weight: bold;
    border-radius: 3px;
    width: 22%;
    height: 22%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  
  .cell:last-child {
    margin-right: 0;
  }
  
  .cell-0 {
    color: transparent;
  }
  
  .cell-2 {
    background-color: #eee4da;
  }
  
  .cell-4 {
    background-color: #eee1c9;
  }
  
  .cell-8 {
    color: #f9f6f2;
    background-color: #f3b27a;
  }
  
  .cell-16 {
    color: #f9f6f2;
    background-color: #f69664;
  }
  
  .cell-32 {
    color: #f9f6f2;
    background-color: #f77c5f;
  }
  
  .cell-64 {
    color: #f9f6f2;
    background-color: #f75f3b;
  }
  
  .cell-128 {
    color: #f9f6f2;
    background-color: #edd073;
    font-size: 45px;
  }
  
  .cell-256 {
    color: #f9f6f2;
    background-color: #edcc62;
    font-size: 45px;
  }
  
  .cell-512 {
    color: #f9f6f2;
    background-color: #edc950;
    font-size: 45px;
  }
  
  .cell-1024 {
    color: #f9f6f2;
    background-color: #edc53f;
    font-size: 35px;
  }
  
  .cell-2048 {
    color: #f9f6f2;
    background-color: #edc22e;
    font-size: 35px;
  }